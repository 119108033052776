.content {
  --background: var(--ion-color-module);
}

.title {
  font-size: 18px;
  color: var(--ion-color-white);
  font-weight: 600;
}

.text {
  color: var(--ion-color-white);
}

