.change_button {
  margin: 20px 0 40px;
}

.change_password_row {
  margin-top: 0px;
}

.invalid {
  color: var(--ion-color-danger);
}

.delete_row {
  margin-top: 20px;

  p {
    color: var(--ion-color-white);
  }
}

.content {
  --background: var(--ion-color-secondary);
}

.item {
  --background: var(--ion-color-secondary);
  --color: var(--ion-color-white);
}
