.grid {
  margin: 0;
  background: var(--ion-color-module);
}

.item {
  --background: var(--ion-color-module-inner-background);
  --color: var(--ion-color-white);
}

.cancel_button {
  margin-top: 20px;
}
