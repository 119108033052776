.content {
  --background: var(--ion-color-module);
}

.diamond_col {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 300px;
  transform: rotate(40deg);
}

.diamond {
  background: url("/../public/assets/diamond/diamond2.png");
  height: 209.8px;
  width: 209.8px;
  background-size: cover;

}


.button_col {
  display: flex;
  flex-direction: column;

  ion-button {
    margin-top: 20px;
  }
}

.dots_wrapper {
  position: absolute;
  height: 210px;
  width: 210px;
}

.blue_area {
  position: absolute;
  height: 150px;
  width: 100px;
  left: 0;
}

.red_area {
  position: absolute;
  height: 150px;
  width: 100px;
  right: 0;
}

.green_area {
  position: absolute;
  height: 100px;
  width: 150px;
  bottom: -10px;
  left: calc(50% - 75px);
}

.dot {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  color: white;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  position: absolute;
  z-index: 20;
  transform: rotate(-40deg);
}

.green_dot {

}

.green0 {
  bottom: 40px;
  right: 2px;
  background: linear-gradient(145deg, var(--ion-color-diamondGreen), var(--ion-color-diamondGreen-shade));
  box-shadow: 2px 2px 5px #806e00;
}


.green1 {
  bottom: 40px;
  left: 2px;
  background: linear-gradient(145deg, var(--ion-color-diamondGreen), var(--ion-color-diamondGreen-shade));
  box-shadow: 2px 2px 5px #806e00;
}

.green2 {
  bottom: 20px;
  left: 40px;
  background: linear-gradient(145deg, var(--ion-color-diamondGreen), var(--ion-color-diamondGreen-shade));
  box-shadow: 2px 2px 5px #806e00;
}

.green3 {
  bottom: 20px;
  right: 40px;
  background: linear-gradient(145deg, var(--ion-color-diamondGreen), var(--ion-color-diamondGreen-shade));
  box-shadow: 2px 2px 5px #806e00;
}

.green4 {
  top: 5px;
  left: calc(50% - 15px);
  background: linear-gradient(145deg, var(--ion-color-diamondGreen), var(--ion-color-diamondGreen-shade));
  box-shadow: 2px 2px 5px #806e00;
}

.red0 {
  left: 0;
  top: 15px;
  background: linear-gradient(145deg, var(--ion-color-diamondRed), var(--ion-color-diamondRed-shade));
  box-shadow: 3px 3px 8px #50090c;
}

.red1 {
  right: 11px;
  bottom: 6px;
  background: linear-gradient(145deg, var(--ion-color-diamondRed), var(--ion-color-diamondRed-shade));
  box-shadow: 3px 3px 8px #50090c;
}

.red2 {
  right: 12px;
  bottom: 48px;
  background: linear-gradient(145deg, var(--ion-color-diamondRed), var(--ion-color-diamondRed-shade));
  box-shadow: 3px 3px 8px #50090c;
}

.red3 {
  right: 32px;
  top: 35px;
  background: linear-gradient(145deg, var(--ion-color-diamondRed), var(--ion-color-diamondRed-shade));
  box-shadow: 3px 3px 8px #50090c;
}

.red4 {
  bottom: 50px;
  left: 10px;
  background: linear-gradient(145deg, var(--ion-color-diamondRed), var(--ion-color-diamondRed-shade));
  box-shadow: 3px 3px 8px #50090c;
}

.blue0 {
  left: 10px;
  bottom: 8px;
  background: linear-gradient(145deg, var(--ion-color-diamondBlue), var(--ion-color-diamondBlue-shade));
  box-shadow: 3px 3px 8px #000058;
}

.blue1 {
  right: 1px;
  top: 13px;
  background: linear-gradient(145deg, var(--ion-color-diamondBlue), var(--ion-color-diamondBlue-shade));
  box-shadow: 3px 3px 8px #000058;
}

.blue2 {
  left: 32px;
  top: 35px;
  background: linear-gradient(145deg, var(--ion-color-diamondBlue), var(--ion-color-diamondBlue-shade));
  box-shadow: 3px 3px 8px #000058;
}

.blue3 {
  left: 10px;
  bottom: 48px;
  background: linear-gradient(145deg, var(--ion-color-diamondBlue), var(--ion-color-diamondBlue-shade));
  box-shadow: 3px 3px 8px #000058;
}

.blue4 {
  bottom: 50px;
  right: 10px;
  background: linear-gradient(145deg, var(--ion-color-diamondBlue), var(--ion-color-diamondBlue-shade));
  box-shadow: 3px 3px 8px #000058;
}

.evaluated_person_list {
  background: transparent;
}

.evaluated_person {
  margin-bottom: 10px;
}

.item_options {
  border: none;
  background-color: var(--ion-color-primary);
  padding-bottom: 10px !important;
  background-clip: content-box;
}


.evaluated_person_inner {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 2px 0;
}

.evaluated_person_text {
  width: 80%;

  p {
    font-size: 16px;
    white-space: normal;

    &:first-child {
      font-size: 20px;
      font-weight: 700;
      letter-spacing: 4px;
    }

    &:not(:first-child) {
      margin-top: 10px;
    }
  }
}

.list_dot {
  width: 30px;
  height: 30px;
  border-radius: 50%;
}

.red_list_dot {
  background: var(--ion-color-diamondRed);
}

.green_list_dot {
  background: var(--ion-color-diamondGreen);
}

.blue_list_dot {
  background: var(--ion-color-diamondBlue);
}

.remaining {
  color: var(--ion-color-white);
  text-align: center;
  margin: 0;
  font-size: 10px;
}

.info_text {
  color: var(--ion-color-white);
  margin-bottom: 0;
  font-size: 14px;
  text-align: center;
}
