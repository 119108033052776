.header_toolbar {
  --background: transparent !important;
  --border-style: none;
  color: var(--ion-color-white);
}

.header:after {
  display: none;
}

.logo {
  max-width: 180px;
}

.back_button {
  padding-right: 50px;
}
