.content {
  --background: var(--ion-color-module-inner-background);
}

.grid {
  padding: 0;
  color: var(--ion-color-white);

  ion-col:not(.image_row) {
    padding: 10px;
  }
}

.download_button {
  height: 26px !important;
  --border-radius: initial !important;
}

.title {
  font-weight: 600;
  color: var(--ion-color-white);
  font-size: 20px;
}

.image_row {
  padding: 0;
}

.title_row {
  background: var(--ion-color-module);
}

.list {
  background: transparent;
}

.session {
  background: var(--ion-color-module-training-item-background);
  color: var(--ion-color-white);
  margin-bottom: 20px;
}

.counter {
  font-weight: 400;
  font-size: 10px;
  text-transform: uppercase;
  letter-spacing: 1.2px;
  margin: 0;
}

.session_title {
  font-size: 24px;
  margin: 5px 0 0;
}

.module_button {
  --border-radius: 4px;
  margin: 8px 20px;
  text-transform: initial;
}