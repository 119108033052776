


/* ---- */

.logo {
  height: 30vh;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    height: 28vh;
  }
}

.text_white {
  color: var(--ion-color-white);
}

.content {
  --background: var(--ion-color-background);
}

.login_buttons {
  margin-top: 10px;
}

.info_buttons {
  margin-top: 40px;

  ion-col {
    display: flex;
    justify-content: center;
  }
}
