.content {
  --background: var(--ion-color-secondary);
}

.grid {
  padding: 5px 0;
}

.range_col {
  padding: 5px 0;
}

.range_item {
  --background: var(--ion-color-dark);
}

.range {
  --knob-size: 18px;
  padding-top: 0;
  padding-bottom: 0;
}

.label {
  --color: var(--ion-color-white) !important;
  padding: 2px 0;
}

.button_col {
  margin-top: 10px;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.cant_change_text {
  color: var(--ion-color-white);
  font-size: 14px;
  margin-left: 20px;
  margin-right: 20px;
  text-align: center;
  margin-bottom: 0;
}

.submit_button {
  --background: var(--ion-color-button);
  width: 80%;
  margin-top: 20px;
}
