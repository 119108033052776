.height-300 {
  height: 300px !important;
}


.content {
  --background: var(--ion-color-module);
}

.video_player {
  max-width: 100%;
  width: 100%;
  position: relative;
  top: -8%;
}

.video_player_alt {
  max-width: 100%;
  width: 100%;
  position: relative;
}

.title {
  color: var(--ion-color-white);
  font-weight: 600;
  font-size: 22px;
  margin-bottom: 20px;
}

.colorWhite {
  color: var(--ion-color-white);
  
}


.slider span {
  height: 14px !important;
  width: 14px !important;
}


.slide {
  padding-bottom: 40px;
  display: flex;
  flex-direction: column;
  align-items: initial;
  min-height: 400px;

  .image {
    max-height: 200px;
  }
}

.innerContent {
  background: var(--ion-color-module-inner-background);
  padding: 10px 10px 30px;

  h5 {
    color: var(--ion-color-white);
    text-align: left;
    margin-top: 18px;
    font-size: 26px;
    font-weight: 500;
  }

  p {
    width: 100%;
    text-align: left;
    color: var(--ion-color-white);
    font-size: 16px;
  }

  .module_button {
    --border-radius: 4px;
    margin: 0 20px;
    text-transform: initial;
  }

  .module_count {
    text-transform: uppercase;
    font-size: 10px;
    margin-top: 8px;
    margin-bottom: 0;
    letter-spacing: 1.2px;
  }
}


.training_list {
  background: transparent;
  margin-top: 20px;

  .training {
    --background: var(--ion-color-module-training-item-background);
    --border-style: none;
    margin-bottom: 10px;

    .trainingTitle {
      display: flex;
      flex-direction: column;
      white-space: normal;

      span {
        font-size: 16px;

        width: 100% !important;
        height: 100% !important;
        margin-bottom: 5px;
      }

      .title {
        font-weight: bold;
      }
    }
  }
}


.slide_locked {
  p, h5 {
    color: #4b616a;
  }
}

.quizAnswer {
  /*min-width: 24%;*/
  padding: 1px 0px;
  font-size: small;
  height: 42px !important;
  margin: 4px;
}

.center {
  text-align: center;
}

.videoRow {
  height: 31vh !important; 
  overflow: hidden;
}

.videoRowAlt {
  height: 100vw;
}

.videoImage {
  position: relative;
  top: -8%; // was -5%
}
