.privacy {
  font-size: 16px !important;
  color: var(--ion-color-white) !important;
  white-space: normal;
}

.privacy_link {
  cursor: pointer;
  color: var(--ion-color-white);
  text-decoration: underline;
}

.invalid_password {
  color: var(--ion-color-primary);
  padding: 0 0 0 20px;
  font-size: 12px;
}

.valid_password {
  color: var(--ion-color-white);
  padding: 0 0 0 20px;
  font-size: 12px;
}

.item {
  --background: transparent;
  color: var(--ion-color-white);
}

.register_button {
  margin-top: 20px;
}

.password_format {
  font-size: 14px;
  margin-left: 20px;
}


.checkmark {
  color: var(--ion-color-success);
}
