.content {
  --background: var(--ion-color-big5);
}

.grid {
  padding: 0;
}

.title_col {
  margin: 20px 0 0;
  padding: 0 10px;

  p {
    color: var(--ion-color-text);
    font-weight: 600;
    font-size: 20px;
    margin: 0;
  }
}

.description {
  margin: 10px 0 20px;
  padding: 0 5px;
  line-height: 24px;
}

.big5_wrapper {
  background: var(--ion-color-big5-wrapper);
  padding: 5px 10px;

  .header {
    margin: 10px 0 0;
    font-weight: 800;
    font-size: 40px;
  }
}

.big5_list {
  background: transparent;
}

.big5_list_scroll {
  overflow: scroll;
  height: 300px;
}

.big5_item {
  --background: transparent;
  --inner-padding-start: 0;
  --padding-start: 0;
  --border-color: var(--ion-color-white);
}

.radioButton {
  height: 20px;
  width: 20px;
  border-radius: 50%;
  background: var(--ion-color-white);
}

.radioButtonSelected {
  height: 20px;
  width: 20px;
  border-radius: 50%;
  background: var(--ion-color-secondary);
}

.list_header {
  text-transform: uppercase;
  padding: 0;
  font-size: 10px;
  font-weight: 400;
  letter-spacing: 1px;
  border: none;
}

.select_buttons_col {
  display: flex;
  flex-direction: column;
  align-items: center;

  ion-button {
    width: 80%;
    --background: var(--ion-color-button);
  }

  p {
    width: 100%;
  }
}

.select_buttons_col_border {
  border-top: 0.5px solid var(--ion-color-white);
}

.progressbar {
  width: 100%;
  display: flex;
  flex-direction: row;
  margin-top: 20px;

  div {
    height: 2px;
    width: 20%;
    padding: 0 1px;
    background: var(--ion-color-medium-light);
    background-clip: content-box;
  }
}

.progressbar_selected {
  div {
    background: var(--ion-color-light);
    background-clip: content-box;
  }

  div.selected {
    background: var(--ion-color-primary);
    background-clip: content-box;
  }

}

.list_item {
  white-space: normal;
  word-break: break-word;
  color: var(--ion-color-text) !important;
}

.read_more {
  font-size: 14px;
  color: var(--ion-color-primary);
}
