
.grid, .gridSurvey {
  background: var(--ion-color-module);
  padding: 5px 0;
  min-height: 100%;


  p, h4 {
    color: var(--ion-color-white);
  }


  ion-card {
    background: var(--ion-color-background);
  }
}

.gridSurvey {
  height: 100%;
}

.cardDimension {
  height: 95%;
}

.surveyList {
  background: transparent;

  ion-item {
    --background: transparent;
    --color: var(--ion-color-white);
  }

  .label {
    --color: var(--ion-color-white);
    color: var(--ion-color-white);
  }

}


.leftButton {
  display: flex;
  justify-content: flex-start;

  ion-button {
    min-width: 120px;
  }
}

.rightButton {
  display: flex;
  justify-content: flex-end;

  ion-button {
    min-width: 120px;
  }
}

.cardContent {
  color: white;

  p {
    font-size: 16px;
  }
}

.startSurveyButtonCol {
  display: flex;
  justify-content: center;
  align-items: center;
}

.startSurveyButton {
  margin-top: 10px;
}
