.content {
  --background: var(--ion-color-background_2);
}

.grid {
  padding-top: 40px;
}

.header_toolbar {
  --background: transparent;
  --border-style: none;
  color: var(--ion-color-white);
}

.header:after {
  display: none;
}


.button_row {
  ion-col {
    margin: 0 15%;
  }
}

.extern_buttons {
  margin-bottom: 20px;
}

.language_row, .darkmode_row {
  ion-col {
    margin: 10px 15%;
  }
}


.version {
  font-size: 16px;
  color: white;
  position: absolute;
  bottom: 10px;
  right: 15px;
}
