.content {
  --background: var(--ion-color-background);
}

.contentWithDiamond {
  --background: var(--ion-color-dark);
}

.round_button {
  margin-top: 20px;
  z-index: 10000;
}

.diamond_logo {
  width: 200px;
}

.type {
  display: flex;
  justify-content: center;

  p {
    color: var(--ion-color-white);
    width: 80%;
    text-align: center;
  }
}

.no_data_text {
  display: flex;
  justify-content: center;
  flex-direction: column;

  p {
    text-align: center;
    color: var(--ion-color-white)
  }
}

.diamond_col {
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
  position: relative;
}




