.innerHtml {
  color: var(--ion-color-white);
  max-height: 66vh;
  margin-bottom: 20px;
  overflow: scroll;
}

.header_toolbar {
  --background: transparent;
  --border-style: none;
  color: var(--ion-color-white);
}

.header:after {
  display: none;
}

.modal {
  --background: var(--ion-color-background);
}

.grid {
  overflow: scroll;
  padding-bottom: 50px;
  margin: 0;
}
